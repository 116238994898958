body {
  margin: 0;
  font-family: $font-primary;
  // 'Roboto Condensed' -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  line-height: 1.6;
  color: #fff;
  background-color: #000;
  // color: #000;
  // background-color: #fff;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

*, *::after, *::before{
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html{
  box-sizing: border-box;
  font-size: 62.5%; //1rem=10px
}

@media only screen and (max-width: 56.25em){
  html{
    font-size: 50%;
  }
}
// @media only screen and (max-width: 37.5em){
//   html{
//     font-size: 40%;
//   }
// }