@mixin heading--4($color: $grey-dark-1) {
  font-family: $font-display;
  font-weight: 400;
  color: $color;
}

.heading--1{
  @include heading--4($grey-light-1);
  font-size: 4.5rem;
  line-height: 1;
}
.heading--2{
  @include heading--4();
  font-size: 4rem;
  font-style: italic;
  line-height: 1;

}
.heading--3{
  @include heading--4($color-p);
  font-size: 1.6rem;
  text-transform: uppercase;

}
.heading--4{
  @include heading--4($grey-dark-1);
  font-size: 1.9rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.header-d{
  font-size: 8rem;
  letter-spacing: 1rem;

  @media only screen and (max-width: $bp-smallest){
    font-size: 6rem;
    letter-spacing: .5rem;
  }
  &>span{
    color: $color-p;
  }
}

.header-small{
  font-size: 4rem;
  
    &>span {
      color: $color-p;
      letter-spacing: .3rem;
    }
}

.btn{
  background-color: $color-p;
  color: #fff;
  border: none;
  border-radius: 0;
  font-family: $font-display;
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 1.8rem 3rem;
  cursor: pointer;
  transition: all .5s;

  &:hover{
    background-color: $color-p-dark;
  }
}

.mb2 {margin-bottom: 2rem;}
.mb3 {margin-bottom: 3rem;}
.mb4 {margin-bottom: 4rem;}
.mb5 {margin-bottom: 5rem;}

.center{
  margin-left: auto;
  margin-right: auto;
}

.dark-input{
  border-radius: 5px;
  outline: none;
  padding: .8rem ;
  background-color: $black;
  border: none;
  color: $white;
  font-size: 1.6rem;

  &::placeholder{
    font-size: inherit;
    font-family: inherit;
  }
}

.top {
  position: absolute;
  left: 0;
  top: -5px;
  width: 100vw;
  height: .5rem;
  background-color: $color-p;

}

.btn-services{
  padding: 1rem 1.5rem;
  border: 1px dashed $color-p;
  margin-top: 2rem;
  background-color: transparent;
  text-decoration: none;
  color: $color-p;
  font-size: 1.5rem;
  transition: all .3s;
  
  &:hover{
    border: 1px solid $color-p;
    scale: 1.02;
    border-radius: 5px;
  }
  &:active{
    opacity: 0.8;
  }
}

.my-link{
  color: $color-p;
  text-decoration: none;
  transition: all 0.3s;
  display: inline-block;
  // transform: scale(1);
  // position: relative;

  &:hover{
    opacity: 0.8;
    transform: scale(1.05);
  }
}

.sp-header{
  margin-bottom: 10rem;
  margin-top: 10rem;
  text-align: center;
  color: $color-p;
  font-size: 3rem;
  position: relative;

  &::before{
    content: '';
    position: absolute;
    width: 2rem;
    height: 6rem;
    border-left: 5px solid $white;
    border-top: 5px solid $white;
    border-bottom: 5px solid $white;
    left: -1.5rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &::after{
    content: '';
    position: absolute;
    width: 2rem;
    height: 6rem;
    border-right: 5px solid $white;
    border-top: 5px solid $white;
    border-bottom: 5px solid $white;
    right: -1.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
}