.portfolio{
  display: grid;
 
  grid-template-columns: repeat(8, [col-start] 1fr [col-end]);
  grid-template-rows: min-content;

  margin-top: -10rem;
  // background-color: #0c0c0d;
  background-color: #18181b;
  // background-color: #28282b;

  &__projects{
    margin-top: 20rem;
    grid-column: col-start 1/-1;
    padding: 0rem 20rem;

    @media only screen and (max-width: $bp-largest){
      padding: 0rem 10rem;
    }
    @media only screen and (max-width: $bp-medium){
      padding: 0rem 5rem;
    }
    @media only screen and (max-width: $bp-small){
      padding: 0rem 3rem;
    }

    .projects__heading{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10rem;
      margin-top: 2rem;
    }

    .projects__container{

      .projects__content{
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        // grid-template-rows: repeat(10, 3rem);
        min-height: 35rem;
        margin-bottom: 10rem;
        
        @media only screen and (max-width: $bp-small){
          margin-bottom: 5rem;
          display: flex;
          flex-direction: column;
        }

        &-image{
          grid-column: 1/7;
          // grid-row: 1/10;
          margin-right: -.5rem;

          @media only screen and (max-width: $bp-small){
            margin-right: 0;
          }
          & img{
            width: 100%;
            // height: 100%;
            min-height: 100%;
            object-fit: cover;
            display: block;
          }
        }

        &-details{
          grid-column: 7/-1;
          // grid-row: 2/-1;
          min-height: 100%;
          background-color: #000;
          margin-left: -.5rem;
          padding: 2rem 2.5rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          margin-top: 2.5rem;

          @media only screen and (max-width: $bp-small){
            margin-right: 0;
            margin-left: 0;
            margin-top: -2rem;
            width: 95%;
            align-self: center;
          }
          &>h2{
            font-size: 2.5rem;
            color: $color-p;
          }
          &>p{
            font-size: 1.6rem;
            margin-bottom: 3rem;
          }
          &>a:link, &>a:visited{
            color: $color-p;
            font-size: 1.4rem;
            text-decoration: none;
            align-self: center;
            border: 1.5px dashed $color-p-dark;
            padding: 1rem 1.5rem;
            transition: .3s;
            
            &:hover{
              border: 1px solid $color-p;
            }
          }
        }
      }
    }
  }
}