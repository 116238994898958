.contact{
  display: grid;
 
  grid-template-columns: repeat(8, [col-start] 1fr [col-end]);
  grid-template-rows: min-content;
  grid-gap: 5rem;

  margin-top: -10rem;
  // background-color: #0c0c0d;
  background-color: #18181b;
  // background-color: #28282b;

  &__text{
    margin-top: 20rem;
    grid-column: col-start 1/ -1;
    padding: 0 15rem;

    @media only screen and (max-width: $bp-large){
      padding: 0 10rem;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__main{
    grid-column: col-start 1/ -1;
    padding: 0 15rem;

    display: flex;
    justify-content: space-between;
    // align-items: center;

    @media only screen and (max-width: $bp-large){
      padding: 0 5rem;
    }

    @media only screen and (max-width: $bp-medium){
      flex-direction: column;
      align-items: center;
    }

    @media only screen and (max-width: $bp-smallest){
      padding: 0 4rem;
    }

    &-form{
      display: flex;
      flex-direction: column;
      // padding: 2rem;

      @media only screen and (max-width: $bp-medium){
        margin-bottom: 10rem;
      }

      // &>h2{
      //   align-self: center;
      //   color: $white;
      //   width: 100%;
      //   text-align: center;
      //   font-size: 2rem;
      // }

      [name='name']{
        @media only screen and (max-width: 24.9375em){
          margin-bottom: 2rem;
        }
      }

      &>.user-inputs{
        &>input{
          width: 20rem;
          @media only screen and (max-width:$bp-smallest){
            width: 100%;
          }
          &:first-of-type{
            margin-right: 1rem;
          }
        }
      }
    }

    .dark-input{
      padding: 2rem 1rem;
    }


    &-info{
      // background-color: red;
      border-radius: 5px;
      width: 40%;
      align-self: flex-start;
      padding-bottom: 5rem;
      // padding: 2rem;

      @media only screen and (max-width: $bp-large){
        width: 50%;
      }
      @media only screen and (max-width: $bp-medium){
        align-self: center;
        width: 80%;
      }
      @media only screen and (max-width: $bp-small){
        width: 100%;
      }

      
      // &>h2{
      //   font-size: 2rem;
      //   text-align: center;
      //   color: $color-p;
      //   margin-bottom: 3rem;
      // }

      .infos{
        padding: 2.5rem ;
        background-color: rgba($white, 0.05);
        border-radius: 5px;

        // @media only screen and (max-width: $bp-large){
        //   margin-right: 0;
        // }

        &__logo{
          display: flex;

          &:not(:last-of-type){
            // background-color: blue;
            margin-bottom: 2rem;
          }

          & svg{
            width: 3rem;
            height: 3rem;
            margin-right: 2rem;
            fill: $color-p;
          }

          .infos__details{
            &>h5{
              font-size: 1.5rem;
            }

            &>p{
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}