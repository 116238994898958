.App{
  // border-left: .2rem solid $color-p;
  // border-right: .2rem solid $color-p;
  // border-top: .2rem solid $color-p;
  // position: relative;
  overflow: hidden;
}

.moving{
  position: absolute;
  width: .5rem;
  height: .5rem;
  border-radius: 50%;
  background-color: $white;
  z-index: 100;
    left: -5px;
    top: 100%;
  // animation: moving 5s linear infinite;
}