.footer{
  grid-column: col-start 1/-1;
  background-image: $background-p;
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 100vw;
  z-index: 200;
  margin-right: -0.2rem;

  &__copy{
    font-size: 1.6rem;
  }

  &__stats{
    font-size: 1.1rem;
  }
}