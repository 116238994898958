.header__container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1000;// new

  background-color: transparent;//

  .header{

    width: 100%;
    height: 8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 4rem;
    margin-top: 2rem;

    @media only screen and (max-width: $bp-smallest){
      padding: 0rem 2.5rem;
    }

    &__logo{
      width: 5rem;
      height: 3rem;
      display: block;
      transition: transform .5s;
      &:hover{
        transform: rotate(360deg);
      }
    }
    
    &__nav{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 5rem;
  
      &:hover{
        cursor: pointer;
        & .span-1, .span-3{
          width: 5rem;
        }
  
        & .span-2{
          width: 3rem;
        }
      }
  
      &>span{
        height: 2px;
        display: block;
        background-color: $white;
        color: $white;
        cursor: pointer;
      }
  
      &-1{
        width: 3rem;
        transition: all .5s;
      }
      &-2{
        width: 5rem;
        margin: 1.5rem 0;
        transition: all .5s;
      }
      
      &-3{
        width: 3rem;
        transition: all .5s;
      }
  
    }
    
  }
}

.body--pass{
  position: absolute;
  height: 100vh;
  width: 0vw;
  background-color: $color-p;
  background-image: linear-gradient(180deg, black 35%, #9c27b0 35%, #9c27b0 65%, black 65%, black 100%);
  background-size: 100%;
  z-index: 5000;
  animation: pass 1s linear;
}