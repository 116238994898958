// Using Sass variables
$color-p: #9c27b0;
$color-p-dark: #6d1b7b;
$color-p-light: #af52bf;

$white: #fff;
$black: #000;


$color-s: #101d2c;

$grey-light-1: #f9f7f6;
$grey-light-2: #aaa;

// $grey-light-3: #f0eeee;

$grey-dark-1: #54483A;
$grey-dark-2: #6d5d4b;

$font-primary: 'Josefin Sans', sans-serif;
$font-display: 'Nunito', sans-serif;
$font-fancy: 'Tilt Prism', cursive;
// $grey-dark-3: #999;
// $grey-light-4: #ccc;

$bp-largest: 75em; //1200px
$bp-large: 67.2em; //1075px
$bp-medium: 56.25em; //900px
$bp-small: 37.5em; //600px
$bp-smallest: 31.25em; //500px

$shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);
$background-p: linear-gradient(45deg, $color-p, $color-p-dark);

//Using css variables
:root{
  --color-primary: #eb2f64;
  --color-primary-light: #FF3366;
  --color-primary-dark: #BA2650;
  --shadow-dark: 0 2rem 6rem rgba(0,0,0,0.3);
  --shadow-light: 0 2rem 5rem 0 rgba(0, 0, 0, 0.06);
  --line-grey: 1px solid #f4f2f2;
}

@mixin btn{
  background-color: #fff;
  border: 1px solid darkgreen;
  padding: 0.5rem;
  font-size: 1rem;
  color: darkgreen;
  border-radius: 3px;
  width: 7rem;
  transition: all 0.3s;
  margin: 1rem 0;
  &>a:link,
  &>a:visited {
    color: inherit;
    text-decoration: none;
  }
  &:hover{
    background-color: darkgreen;
    color: #fff;
    cursor: pointer;
  }
}