.about{
  display: grid;
 
  grid-template-columns: repeat(8, [col-start] 1fr [col-end]);
  grid-template-rows: min-content;

  margin-top: 12rem;

  &__main{
    grid-column: col-start 1/ -1;
    
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-left: 15rem;//
    padding-right: 15rem;//
    
    @media only screen and (max-width: $bp-large){
      padding-left: 10rem;//
      padding-right: 10rem;//
    }
    
    @media only screen and (max-width: $bp-medium){
      padding-left: 5rem;//
      padding-right: 5rem;//
    }
    
    @media only screen and (max-width: $bp-small){
      padding-left: 3rem;//
      padding-right: 3rem;//
    }
    
    .main__details{
      width: 100%;
      color: $white;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      
      @media only screen and (max-width: $bp-medium){
        flex-direction: column;
      }
      @media only screen and (max-width: $bp-smallest){
        // justify-content: center;
      }
      
      &-text{
        flex: 1;
        padding-right: 2rem;
        font-size: 1.6rem;//
        display: flex;
        flex-direction: column;
        
        .change-color{
          color: $color-p
        }
        
        @media only screen and (max-width: $bp-medium){
          order: 2;
          width: 100%;
        }

        @media only screen and (max-width: $bp-smallest) {
          padding-right: 0;
        }

        &>a:link, &>a:visited{
          color: $white;
          background-image: linear-gradient(45deg, $color-p, $color-p-dark);
          padding: 1rem 1.5rem;
          border-radius: 3px;
          text-decoration: none;
          align-self: center;
          transition: all .3s;
          // position: relative;
          &:hover{
            scale: 1.02;
          }
          &:active{
            scale: 1.03;
            filter: brightness(1.5);
          }
        }

        .paragraph{
          position: relative;
          margin-bottom: 20rem;
          font-size: 2.5rem;
          padding: 2rem;
          display: flex;
          justify-content: space-between;
          gap: 4rem;
          align-items: center;
          
          @media screen and  (max-width: $bp-large) {
            display: block;
          }
          @media screen and  (max-width: $bp-smallest) {
            display: flex;
            flex-direction: column;
            // align-items: center;
          }

          &__order{
            order: 1;
            @media screen and (max-width: $bp-large){
              float: right;
              margin-left: 2rem;
            }
            @media screen and (max-width: $bp-smallest) {
              order: 0;
              float: none;
              margin-left: 0;
            }
          }

          &__left{

            @media screen and (max-width: $bp-large){
              float: left;
              margin-right: 2rem;
            }

            @media screen and (max-width: $bp-smallest) {
              order: 0;
              float: none;
              margin-right: 0;
            }
          }
          

          &__text{
            color: #c084fc;
            // text-align: center;
          }

          &>img, &>.img{
            width: 50%;

           @media screen and (max-width: $bp-smallest) {
              width: 100%;
              display: flex;
              justify-content: center;
            }
          }

          .common{
            position: absolute;
            width: 2.5rem;
            height: 2.5rem;
          }

          &__before-1{
            border-left: 5px solid #9c27b0;
            border-top: 5px solid #9c27b0;
            left: -1.5rem;
            top: -1.5rem;
          }
          &__after-1{
            border-right: 5px solid #9c27b0;
            border-bottom: 5px solid #9c27b0;
            right: -1.5rem;
            bottom: -1.5rem;
          }
          &__before-2{
            border-left: 5px solid #fff;
            border-top: 5px solid #fff;
            left: -1.5rem;
            top: -1.5rem;
          }
          &__after-2{
            border-right: 5px solid #fff;
            border-bottom: 5px solid #fff;
            right: -1.5rem;
            bottom: -1.5rem;
          }
        }
      }
      
      &-photo{
        flex: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        
        &>img{
          object-fit: cover;
          width: 90%;
          border-radius: 2rem;
          display: block;
        }
      }
    }
  }

  .about__services{
    grid-column: col-start 1/-1;
    margin-top: 8rem;

    padding-left: 15rem;
    padding-right: 15rem;

    @media only screen and (max-width: $bp-large){
      padding-left: 10rem;//
      padding-right: 10rem;//
    }
    @media only screen and (max-width: $bp-medium){
      padding-left: 5rem;//
      padding-right: 5rem;//
    }

    @media only screen and (max-width: $bp-small){
      padding-left: 3rem;//
      padding-right: 3rem;//
    }
    
    
    display: flex;
    flex-direction: column;
    align-items: center;

    .services__text{
      font-size: 4rem;
      letter-spacing: 1rem;
      &>span{
        color: $color-p;
      }
    }
    
    .services{
      display: flex;
      gap: 5rem;
      align-items: flex-start;
      justify-content: center;

      @media only screen and (max-width: $bp-large){
        flex-direction: column;
      }

      &__content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 2.5rem 1.5rem;
        border-radius: 2rem;
        transition: all 1s;
        background-color: rgba($color: $white, $alpha: .05);

        flex-basis: 33%;
      
        position: relative;
        z-index: 1;
        // height: 40rem;
        padding-top: 15rem;
        overflow: hidden;

        @media only screen and (max-width: $bp-large){
          flex-basis: auto;
          height: max-content;
        }

        &-icon{
          width: 8rem;
          height: 8rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 2rem;
          border-radius: 2rem;

          position: absolute;
          top: 3rem;

          & svg{
            width: 7rem;
            height: 7rem;
            fill: $white;
          }
        }

        &>h1{
          font-size: 2.5rem;
        }

        &>p{
          font-size: 2rem;
          display: flex;
          text-align: center;
          flex-direction: column;

          & button{
            border: none;
            outline: none;
            color: #fbbf24;
            background-color: transparent;
            cursor: pointer;
          }
        }



        &:hover::before{
          width: 100%;
          height: 100%;
          top: 0rem;
        }

        &:hover .btn-services{
          background-color: black;
        }
        &:hover h1{
          background-color: black;
          padding: 1rem 1.5rem;
          color: $color-p;
        }

        &::before{
          content: '';
          width: 10rem;
          height: 10rem;
          border-radius: 2rem;
          background-image: $background-p;
          position: absolute;
          top: 3rem;
          z-index: -1;
          // transition: all .5s;
          transition: width .5s, height .5s, top .2s;
        }
      }
    }

  }

  .about__skills{ 
    grid-column: col-start 1/-1;
    margin-top: 8rem;
    margin-bottom: 8rem;

    padding-left: 15rem;
    padding-right: 15rem;
    @media only screen and (max-width: $bp-large){
      padding-left: 10rem;//
      padding-right: 10rem;//
    }
    @media only screen and (max-width: $bp-medium){
      padding-left: 5rem;//
      padding-right: 5rem;//
    }
    @media only screen and (max-width: $bp-small){
      padding-left: 3rem;//
      padding-right: 3rem;//
    }

    display: flex;
    flex-direction: column;
    align-items: center;

    .skills{
      list-style: none;

      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
      grid-column-gap: 5rem;
      grid-row-gap: 6rem;
      width: 100%;

      @media only screen and (max-width: $bp-medium){
        grid-row-gap: 3rem;
      }
      
      &__list{
        width: 100%;
        
        &-skill{
          margin-bottom: .5rem;
          font-size: 1.5rem;
          font-weight: 500;
          font-style: italic;
        }
        
        &-percent{
          background-color: rgba($color: $white, $alpha: 0.2);
          width: 100%;
          height: 4rem;
          border-radius: 50px;
          position: relative;
          &>div{
            height: 3.6rem;
            background-image: $background-p;
            position: absolute;
            left: 0;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.2rem;

            top: 50%;
            transform: translateY(-50%);
            margin-left: 0.2rem;
          }
        }
      }
    }

  }
}

//MERN
.mern{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 30rem;
  padding: 3rem;
  align-items: center;
  border-radius: 4px;
  margin: 0 auto;
  background-color: rgba($color: #fff, $alpha: 0.05);

  @media screen and (max-width: $bp-large){
    margin-bottom: 1rem;
  }
  @media screen and (max-width: $bp-small){
    gap: 0.5rem;
    padding: 1rem;
    width: 24rem;
  }
  @media screen and (max-width: $bp-smallest){
    width: 30rem;
    gap: 2rem;
    padding: 3rem;
    margin: 0 0;
  }
  
  &__cover{
    display: flex;
    gap: 2rem;
    
    @media screen and (max-width: $bp-small){
      gap: 0.5rem;
    }
    @media screen and (max-width: $bp-smallest){
      gap: 4rem;
    }
  }

  &__icon{
    font-size: 10rem;
  }
}

// .bootstrap-mui{
//   background-color: green;
//   display: flex;
//   gap: 2rem;
//   width: 30rem;
//   padding: 3rem;
// }