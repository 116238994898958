.modal__container{

  background-color: $black;
  height: 100vh;

  .modal__header{
    width: 100%;
    height: 8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 4rem;
    margin-top: 2rem;

    @media only screen and (max-width: $bp-smallest){
      padding: 0rem 2.5rem;
    }

    &-logo{
      width: 5rem;
      height: 3rem;
      display: block;
      transition: transform .5s;
      &:hover{
        transform: rotate(360deg);
      }
    }
    
    &-nav{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 5rem;
      height: 5rem;
      position: relative;
      cursor: pointer;
  
      &:hover{
        & .modal__header-nav-span-1{
          transform: rotate(90deg);
        }
        
        & .modal__header-nav-span-2{
          transform: rotate(90deg);
        }
      }
  
      &>span{
        display: block;
        background-color: $white;
        color: $white;
        cursor: pointer;
        position: absolute;
      }
  
      &-span-1{
        width: 3rem;
        transition: all .5s;
        display: inline-block;
        height: 2px;
        top: .5rem;
      }
      &-span-2{
        height: 3rem;
        width: 2px;
        transition: all .5s;
      }
  
    }
    
  }

}

.modal__content{
  display: flex;
  justify-content: center;
  // background-color: red;
    margin-top: 10rem;
  &--list{
    list-style: none;

    &>li:not(:last-of-type){
      margin-bottom: 3rem;
    }

    &>li>a:link, &>li>a:visited{
      color: $white;
      font-size: 2rem;
      text-decoration: none;
      text-transform: uppercase;
      display: block;
      padding: 1rem 1.5rem;
      background-image: linear-gradient(120deg, transparent 0%, transparent 50%, $color-p 50%);
      background-size: 215%;
      transition: all .5s;

      &:hover{
        background-position: 100%;
      }
    }
  }
}
