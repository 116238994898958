.homepage{
  display: grid;
 
  grid-template-columns: repeat(8, [col-start] 1fr [col-end]);
  grid-template-rows: 20vh 80vh;

  @media only screen and (max-width: $bp-medium){
    grid-template-rows: 20vh 60vh 20vh;
  }


  &__social{
    grid-column: col-start 1/ col-end 1;
    grid-row: 2/3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;

    @media only screen and (max-width: $bp-medium){
      grid-row: 3/4;
      grid-column: 1/-1;
      flex-direction: row;
    }
    
    &>li{
      
      &:not(:last-of-type){
        margin-bottom: 3rem;
        @media only screen and (max-width: $bp-medium){
          margin-bottom: 0rem;
          margin-right: 5rem;
        }
      }

      & svg{
        fill: $white;
        width: 5rem;
        height: 5rem;
        transition: all .3s;
      }
    }

    .social__list:hover svg{
      fill: $color-p
    }
  }
  
  &__main{
    grid-column: col-start 2/ col-end 7;
    grid-row: 2/3;
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10rem;
    @media only screen and (max-width: $bp-medium){
      justify-content: flex-start;
    }

    .main__name{
      font-size: 4rem;
      margin-bottom: 10rem;//

      @media only screen and (max-width: $bp-smallest){
        font-size: 3.5rem;
      }

      & span{
        color: $color-p;
      }
    }

    .main__typical{
      font-size: 2.5rem;
      margin-bottom: 10rem;
      & p{
        color: $color-p;
      }
    }

    .main__send{

      & a:link, a:visited{
        &::after{
          content: 'mail me';
          font-size: 1rem;
          color: $color-p;
          position: absolute;
          width: max-content;
          transition: all 0.3s;
          opacity: 0;
        }
        & svg{
          width: 3rem;
          height: 3rem;
          fill: $grey-light-2;
          display: inline-block;
          &:hover{
            fill: $white;
          }
        }
        
        &::after{
          animation: mail-me 1s infinite;
          z-index: -5;
          cursor: context-menu;

        }
  
        &:hover::after{
          opacity: .8;
          animation: none;
        }
      }
    }
  }
  
  &__dave{
    position: fixed;
    // width: 60rem;
    // height: 60vh;
    // max-width: 60rem;
    // max-height: 60vh;
    width: 40rem;
    height: 60rem;
    bottom: -10rem;
    right: 0;
    z-index: -10;
    // background-image:
    // url('../../public/img/dave.png');
      background-position: center;
      background-size: cover;

    @media only screen and (max-width: $bp-medium){
      left: 50%;
      transform: translateX(-50%);
    }
    
    // & .dave__image{
    //   width: 100%;
    //   height: 100%;
    //   // background-position: center;
    //   background-size: cover;
    // }
  }
  
}
