@keyframes modal-add {
  0%{
    height: 0vh;
  }

  50%{
    height: 50vh;
  }

  100%{
    height: 100vh;
  }
  
}

@keyframes modal-remove {
  0%{
    height: 100vh ;
  }
  
  50%{
    height: 50vh;
  }
  
  100%{
    height: 0vh;
  }
  
}

@keyframes mail-me {
  0%{
    opacity: 0;
  }

  100%{
    opacity: 1;
  }
}

@keyframes pass {
  0%{
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    // right: 0;
    // bottom: 0;
    // transform: translateX(0);
  }
  
  50%{
    // height: 100vh;
    // width: 100vw;
    // background-color: green;
    // background-image: linear-gradient(120deg, #000 25%, #000 25%, $color-p 50%, $color-p 50%);
    // transform: translateX(-50vw);
  }

  100%{
    height: 100vh;
    width: 0;
    top: 0;
    left: 0;
    // transform: translateX(-100vw);
  }
}

@keyframes moving {
  0%{
    left: -5px;
    top: 100%;
  }
  25%{
    left: -5px;
    top: -5px;
  }
  50%{
    left: 100%;
    top: -5px;


  }
  75%{
    left: 100%;
    top: 100%;
  }
  100%{
    left: -5px;
    top: 100%;
  }
}
// @keyframes moving {
//   0%{
//     left: -5px;
//     top: 100%;
//   }
//   25%{
//     left: -5px;
//     top: -5px;
//   }
//   50%{
//     left: 100%;
//     top: -5px;


//   }
//   75%{
//     left: 100%;
//     top: 100%;
//   }
//   100%{
//     left: -5px;
//     top: 100%;
//   }
// }
